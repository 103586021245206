<template>
    <div class="webapp-wrapper">
        <div
            ref="preview"
            :class="{ 'hidden': !(unitPreview && !showCanvas), 'show-start-webapp-button': !startUnitOnLoad }"
            class="unit-preview"
            @click="onStartApp"
        >
            <img
                :src="unitPreview"
                alt=""
                class="unit-preview-image"
            >
            <Icon class="start-webapp-button" name="icon_play" />
            <div v-if="loading" class="loading">
                <LoadingIndicator />
            </div>
        </div>
        <canvas id="unity-canvas" ref="unityCanvas" />

        <template v-if="shouldShowControls">
            <PageUnitLaunchControlsEdit v-if="isAuthoring" />
            <PageUnitLaunchControlsView v-else />
        </template>

        <template v-if="addModals">
            <ModalProgress />
            <ModalNotification />
        </template>
    </div>
</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import {Feature} from '@/Models/Features/Feature';
import {featureRepositoryKey} from '@/Vue/Bootstrap/InjectionKeys';
import type {UnityInstance} from '@/Models/Unity/UnityInstance';
import * as MsCognitiveSpeechSdk from 'microsoft-cognitiveservices-speech-sdk';
import EventType from '@/Utility/EventType';
import {trans} from '@/Utility/Helpers/trans';
import LoadingIndicator from '@/Vue/Common/LoadingIndicator.vue';
import Icon from '@/Vue/Common/Icon.vue';
import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
import PageUnitLaunchControlsEdit from '@/Vue/Units/PageUnitLaunchControlsEdit.vue';
import PageUnitLaunchControlsView from '@/Vue/Units/PageUnitLaunchControlsView.vue';

declare global {
    // noinspection JSUnusedGlobalSymbols
    /**
     * Make properties of unity loader on window scope known to typescript.
     */
    interface Window {
        getUnitLaunchData: (() => string) | undefined;

        navigateBack: (() => void) | undefined;

        MsCognitiveSpeechSdk: typeof MsCognitiveSpeechSdk | undefined;

        createUnityInstance: ((
            canvas: HTMLCanvasElement,
            config: any,
            onLoadProgress: (number: number) => void
        ) => Promise<UnityInstance>) | undefined;
    }
}

export default defineComponent({
    components: {
        PageUnitLaunchControlsView,
        PageUnitLaunchControlsEdit,
        ModalNotification,
        ModalProgress,
        Icon,
        LoadingIndicator
    },
    props: {
        courseUid: {
            type: String,
            default: null,
            required: false,
        },
        unitUid: {
            type: String,
            required: true,
        },
        unitRevisionUid: {
            type: String,
            required: true,
        },
        unitTitle: {
            type: String,
            required: true,
        },
        unitPreview: {
            type: String,
            default: null,
        },
        accessToken: {
            type: String,
            required: true,
        },
        isPreview: {
            type: Boolean,
            required: true,
        },
        isAuthoring: {
            type: Boolean,
            required: true,
        },
        buildFilesBaseName: {
            type: String,
            default: '3spinLearning'
        },
        startUnitOnLoad: {
            type: Boolean,
            default: true,
        },
        addModals: {
            type: Boolean,
            default: false,
        },
        tenantUid: {
            type: String,
            default: window.currentUser?.tenant?.uid,
        },
        webappHash: {
            type: String,
            default: null,
        },
    },

    emits: {
        onWebappStopped: () => true,
    },

    data() {
        return {
            featureRepository: inject(featureRepositoryKey),
            unityInstance: null as UnityInstance | null,
            showCanvas: false,
            showControls: true,
            loading: false,
            unitHasFinished: false,
        };
    },

    computed: {

        cacheParam() {
            return this.webappHash !== null ? '?cache=' + this.webappHash : '?cache=' + this.releaseVersion;
        },

        playerVersion() {
            if (this.featureRepository?.active(Feature.FeatureWebappPlayerDev)) {
                return 'dev';
            }

            return this.releaseVersion;
        },

        buildUrl() {
            return `/storage/players/${this.playerVersion}/3spinLearning`;
        },

        releaseVersion() {
            return window.appData.APP_RELEASE;
        },

        streamingAssetsUrl() {
            return `/storage/players/${this.playerVersion}/StreamingAssets`;
        },

        unityCanvasElement() {
            return this.$refs.unityCanvas as HTMLCanvasElement;
        },

        previewElement() {
            return this.$refs.preview as HTMLDivElement;
        },

        layoutElement() {
            return this.$refs.layoutContent as HTMLDivElement;
        },

        shouldShowControls() {
            return this.showControls && this.showCanvas && !this.loading;
        },
    },

    mounted() {
        window.getUnitLaunchData = () => JSON.stringify({
            course_uid: this.courseUid,
            unit_uid: this.unitUid,
            unit_revision_uid: this.unitRevisionUid,
            tenant_uid: this.tenantUid,
            access_token: this.accessToken,
            is_preview: this.isPreview,
            is_authoring: this.isAuthoring,
        });

        window.navigateBack = () => {
            this.unitHasFinished = true;
            this.$emit('onWebappStopped');
        };

        window.MsCognitiveSpeechSdk = MsCognitiveSpeechSdk;

        if (this.startUnitOnLoad) {
            this.startApp();
        }

        document.addEventListener('fullscreenchange', this.onChangeFullscreenMode);
        window.addEventListener('beforeunload', this.onBeforeUnload);
    },

    beforeUnmount() {
        document.removeEventListener('fullscreenchange', this.onChangeFullscreenMode);
        window.removeEventListener('beforeunload', this.onBeforeUnload);
    },

    methods: {
        startApp() {
            this.loading = true;

            // attach loader script
            const script = document.createElement('script');
            script.src = this.getBuildFileUrl('loader.js');
            script.onload = this.onLoaderScriptLoaded;
            script.onerror = this.onLoaderScriptError;
            document.body.appendChild(script);
        },

        onLoaderScriptError(e: Event) {
            console.error(e);
            this.loading = false;
            this.onLoadError('Unable to load script<br><br><b>' + e.target?.src + '</b>');
        },

        onLoaderScriptLoaded() {
            const config = {
                dataUrl: this.getBuildFileUrl('data.gz'),
                frameworkUrl: this.getBuildFileUrl('framework.js.gz'),
                codeUrl: this.getBuildFileUrl('wasm.gz'),
                streamingAssetsUrl: this.streamingAssetsUrl,
                showBanner: this.onUnityError,
                matchWebGLToCanvasSize: true,
                devicePixelRatio: window.devicePixelRatio,
            };

            if (!window.createUnityInstance) {
                throw new Error('createUnityInstance is not defined');
            }

            window.createUnityInstance(this.unityCanvasElement, config, this.onLoadProgress)
                .then(this.onLoadFinished)
                .catch(this.onLoadError)
                .finally(() => {
                    this.showCanvas = true;
                    this.loading = false;
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                });
        },

        onLoadProgress(progress: number) {
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'), progress);
        },

        onLoadFinished(unityInstance: UnityInstance) {
            this.unityInstance = unityInstance;
        },

        onLoadError(message: string) {
            this.$root!.showErrorDialog(message);
        },

        onUnityError(message: string, type: 'error' | 'warning') {
            switch (type) {
                case 'error':
                    this.$root!.showErrorDialog(message);
                    break;
                case 'warning':
                    console.warn('onUnityError', message, type);
                    break;

            }
        },

        onStartApp() {
            if (!this.startUnitOnLoad) {
                this.startApp();
            }
        },

        toggleControls() {
            this.showControls = !this.showControls;
        },

        triggerFullscreen() {
            this.unityInstance?.SetFullscreen(1);

            return this;
        },

        onChangeFullscreenMode() {
            if (document.fullscreenElement !== null) {
                this.unityCanvasElement.classList.add('fullscreen');
            } else {
                this.unityCanvasElement.classList.remove('fullscreen');
            }
        },

        onBeforeUnload(e: BeforeUnloadEvent) {
            if (!this.unloadWebapp()) {
                return;
            }

            e.preventDefault();

            // remove once chrome >= 119 is required
            // noinspection JSDeprecatedSymbols
            e.returnValue = true;
        },

        unloadWebapp() {
            if (!this.unityInstance || this.unitHasFinished) {
                return false;
            }

            this.unityInstance.SendMessage('WebAppJavaScriptEventHandler', 'OnBeforeUnload');

            return true;
        },

        /**
         * @return path of the file with the given extension inside the build directory
         */
        getBuildFileUrl(extension: string): string {
            return `${this.buildUrl}/${this.buildFilesBaseName}.${extension}` + this.cacheParam;
        },
    },
});
</script>

<style lang="scss" scoped>
.unit-preview {
    border-radius: var(--card-border-radius-small);
    background-color: white;
    position: relative;
    overflow: hidden;

    &:not(.hidden) + canvas {
        display: none;
    }

    &.hidden {
        display: none;
    }

    .loading {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: black;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .unit-preview-image {
        width: 100%;
        height: 100%;
    }

    .start-webapp-button {
        position: absolute;
        height: 20%;
        width: 20%;
        top: calc(50% - calc(20% / 2));
        left: calc(50% - calc(20% / 2));
        color: white;
        display: none;
        transition: color .1s;
    }

    &.show-start-webapp-button {
        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.4);
            transition: background-color .1s;
        }

        &:hover {
            &:before {
                background-color: rgba(0, 0, 0, 0.3);
            }

            .start-webapp-button {
                color: var(--color-primary-hover);
            }
        }

        .start-webapp-button {
            display: block;
        }
    }


}

canvas {
    img {
        width: 100%;
        height: auto;
    }

    width: 100%;
    height: 100%;
}

canvas:not(.fullscreen) {
    border-radius: var(--card-border-radius-small);
    background-color: black;
}

canvas.fullscreen {
    background-color: black;
}

.unit-preview {
    height: 100%;
    width: 100%
}

.webapp-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
}

:deep(.controls) {
    position: absolute;
    top: 24px;
    right: 24px;
    max-height: calc(100% - 48px);
    user-select: none;
}

</style>
